.DoingContainer {
  position: relative;
}
.DoinggraphicalBackgroundArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url("../../../images/ambassadors/left-vector.svg"),
    url("../../../images/ambassadors/right-vector.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: 0px -200px, 100% -150px;
  z-index: 0;
  height: 400px;
}

.DoingtopGraphicalArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 175px; */
  gap: 30px;
}

.DoingtopGraphicalAreaText {
  color: #eb4176;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Audiowide";
}

.DoingtopGraphicalAreaParagraph {
  font-size: 20px;
  color: #6a6a6a;
  width: 475px;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 32px;
  width: 35%;
}

@media (max-width: 768px) {
  .DoinggraphicalBackgroundArea {
    background-image: none;
    height: 250px;
  }
  .DoingtopGraphicalAreaText {
    font-size: 14px;
  }
  .DoingtopGraphicalAreaParagraph {
    font-size: 12px;
    width: 65%;
    line-height: 24px;
  }
}
