.videoOverlayContainer {
  position: relative;
  width: 100%;
  height: auto; 
  background-color: #ccc;
  border-radius: 20px;
}

.waitlistArea::before {
  content: "";
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.waitlistArea {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-height: 712px;
  object-fit: cover;
}

.waitlistArea > video {
  width: 100%;
  height: auto;
  display: block;
}

.overlayVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.overlayVideoText {
  font-size: 32px;
  font-family: "Audiowide";
  margin-bottom: 10px;
}

.overlayVideoButton {
  height: 46px;
  width: 200px;
  padding: 10px 30px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.overlayVideoButton:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .videoOverlayContainer {
    height: 360px;
  }
  .videoOverlayContainer > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlayVideoText {
    font-size: 10px;
  }

  .overlayVideoButton {
    height: 23px;
    width: fit-content;
    padding: 2px 10px;
    font-size: 8px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
  }
}
