.WhatAmbassadorsDoCardContainer{
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     gap: 95px;
     padding: 42px 55px;
     -webkit-box-shadow: 0px 20px 38px -8px rgba(0, 0, 0, 0.42);
     -moz-box-shadow: 0px 20px 38px -8px rgba(0, 0, 0, 0.42);
     box-shadow: 0px 20px 38px -8px rgba(0, 0, 0, 0.2);
     border-radius: 20px;
}

.WhatAmbassadorsDoLeftImage{
     height: 615px;
     width: 618px;
     object-fit: fill;
     border-radius: 20px;
}

.WhatAmbassadorsDoRightSideContainer{
     display: flex;
     align-items: center;
}

.WhatAmbassadorsDoContent{
     display: flex;
     flex-direction: column;
     gap: 2rem;
}

.WhatAmbassadorsDo{
     font-family: "Nohemi";
     font-size: 20px;
     color: #6A6A6A;
     line-height: 32px;
     margin: 0;
     padding: 0;
     padding-right: 96px;
}
.WhatAmbassadorsDo .ContentHeading{
     color: #533884;
     font-weight: bold;
}
.WhatAmbassadorsDoMobileContainer{
     display: none;
}

@media(max-width: 768px){

     .WhatAmbassadorsDoCardContainer{
          grid-template-columns: 1fr;
          padding: 25px 25px 50px 25px;
          gap: 54px;
     }
     .WhatAmbassadorsDoContent{
          display: none;
     }
     .WhatAmbassadorsDoLeftImage{
          height: 319px;
          width: 100%;
          object-fit: fill;
     }
     .WhatAmbassadorsDoMobileContainer{
          display: flex;
          flex-direction: column;
          gap: 2rem;
          text-align: center;
          padding: 0px 1rem;
     }

     .WhatAmbassadorsDoMobile{
          display: flex;
          flex-direction: column;
          gap: 26px;
     }
     .ContentHeadingMobile{
          color: #533884;
          font-weight: 800;
          margin: 0;
     }
     .WhatAmbassadorsDoMobileDescription{
          margin: 0;
          font-size: 12px;
          color: #6A6A6A;
          line-height: 24px;
     }

}



