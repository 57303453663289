@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css";

.SelectTrigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1;
  height: 40px;
  width: 100%;
  gap: 5px;
  background-color: white;
  color: black;
  border: 1px solid #cecece;
}
.SelectTrigger:hover {
  background-color: #eee;
}
.SelectTrigger:focus {
  /* box-shadow: 0 0 0 2px black; */
}
.SelectTrigger[data-placeholder] {
  color: #AEAEAE;
}

.SelectIcon {
  color: black;
}
.SelectContent {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 16px;
  line-height: 2rem;
  color: black;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}
.SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
  font-size: 20px;
}
.SelectItem[data-highlighted] {
  outline: none;
  background-color: #eee;
  color: black;
  cursor: pointer;
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}
