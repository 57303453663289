.rolePlayFeatures {
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.rolePlayCard {
  display: flex;
  flex-direction: column;
  height: 320px;
  align-items: center;
  justify-content: space-evenly;
  padding: 48px 40px;
  border-radius: 20px;
  color: white;
  gap: 18px;
}

.rolePlayCardIcon {
  font-size: 58px;
}

.rolePlayCardTitle {
  font-size: 28px;
  font-family: "Audiowide";
  text-align: center;
}
.confidence {
  background-color: #252153;
}

.awareness {
  background-color: #e2b44b;
}

.communication {
  background-color: #533884;
}

.transformation {
  background-color: #eb4176;
}

@media (max-width: 768px) {
  .rolePlayFeatures {
    max-width: 100%;
    overflow: scroll;
  }

  .rolePlayCardTitle {
    font-size: 20px;
  }

  .rolePlayCard {
    display: flex;
    flex-direction: column;
    width: 264px;
    height: auto;
    align-items: center;
    justify-content: space-evenly;
    padding: 30px 20px;
    border-radius: 20px;
    color: white;
    gap: 14px;
  }
}
