.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 20s ease-in-out;
}

.TaaraContainer {
  display: flex;
  flex-direction: column;
  /* width: 100vw; */
  align-items: center;
}

.TaaraIntroImage {
  width: 95%;
  height: 640px;
  background-image: url("../../images/taara/TaaraBackground.avif");
  background-position: center;
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  border-radius: 20px;
  object-fit: cover;
  opacity: 0; /* Start with opacity 0 */
  animation: fadeIn 0.5s ease-in forwards; /* Animation for fade-in */
}
@keyframes fadeIn {
  from {
    opacity: 0; /* starting opacity */
  }
  to {
    opacity: 1; /* ending opacity */
  }
}

.TaaraIntroLeftImage {
  flex: 1;
  height: 100%;
  border-radius: 20px;
}

.TaaraIntroRightImage {
  flex: 1;
  padding: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Audiowide";
  font-size: 40px;
  color: white;
  border-radius: 20px;
}

.TaaraFirstIntro {
  height: 670px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  font-size: 25px;
  font-family: "Nohemi";
  text-align: center;
  background-image: url("../../images/left-yellow-vector.svg"),
    url("../../images/right-vector.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top left, top right;
  z-index: -1;
}

.TaaraFirstIntroTopText {
  color: black;
  line-height: 40px;
}

.TaaraFirstIntroBottomText {
  color: #4e327a;
}

.TaaraFaceArea {
  background-image: url("https://taara-quest.s3.eu-central-1.amazonaws.com/images/taara/TaaraFace.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-size: 33px;
  justify-content: center;
  width: 90%;
  border-radius: 20px;
  position: relative;
  top: -30px;
  height: 600px;
}

.TaaraFaceArea > span {
  font-size: 20px;
  margin-top: 10px;
}

.TaaraOneLiners {
  display: flex;
  width: 100%;
  height: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 25px;
  color: black;
}

.TaaraReason {
  width: 90%;
  border-radius: 20px;
  height: 300px;
  background-color: #eb4176;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 500;
}

.TaaraReason > span {
  font-size: 20px;
  margin-top: 50px;
  font-weight: 100;
}

.TaaraSocial {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  height: 900px;
  align-items: center;
  justify-content: center;
  /* -webkit-box-shadow: 22px 41px 39px -28px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 22px 41px 39px -28px rgba(0, 0, 0, 0.75);
  box-shadow: 22px 41px 39px -28px rgba(0, 0, 0, 0.75); */
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-top: 72px;
}

.TaaraSocialHeading {
  font-family: "Audiowide";
  color: #eb4176;
  font-size: 45px;
}

.TaaraSocialButton {
  background-color: #eb4176;
  color: white;
  height: 50px;
  width: 170px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.TaaraSocialButton > a:visited {
  text-decoration: none;
}

.TaaraSocialButton:hover {
  color: #eb4176;
  background-color: white;
  border: 1px solid #eb4176;
  height: 48px;
}

.TaaraSocialCards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
}

.TaaraThoughts {
  width: 90%;
  height: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 50px;
  color: #533884;
  margin-top: 5%;
}

.TaaraThoughts > span {
  text-align: center;
  color: black;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 15%;
}

.TaaraDescription {
  display: flex;
  width: 90%;
  height: 420px;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.TaaraLeftDescription {
  height: 100%;
  /* padding: 30px; */
  /* background-image: url("../../images/taara/TaaraTechy.webp"); */
}

.TaaraRightDescription {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 30px;
  background-color: #533884;
  color: white;
  font-size: 20px;
  border-radius: 20px;
  justify-content: space-evenly;
  font-weight: light;
  line-height: 30px;
}

.TaaraStoryShare {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 520px;
  font-size: 50px;
  color: #a89bc1;
}

.TaaraStoryShare > span {
  color: #533884;
}

.TaaraIntroductionVideoTitle {
  width: 50%;
  height: 100px;
  display: flex;
  padding: 0px 20px;

  align-items: center;
  justify-content: center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #e2b44b;
  font-size: 28px;
  color: white;
}

.TaaraIntroVideoArea {
  /* width: 100%; */
  height: 720px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url("../../images/video-thumbnail.svg"); */
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}

.TaaraMissionArea {
  display: flex;
  width: 100%;
  padding: 30px;
  height: 650px;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/topPurpleVector.svg");
  background-repeat: no-repeat;
}

.TaaraMissionLeft {
  flex: 1;
  width: 100%;
  position: relative;
  right: -180px;
  top: -130px;
  display: flex;
  flex-direction: column;
  color: #eb4176;
  font-size: 40px;
}

.TaaraMissionLeft > span {
  color: #252153;
  font-size: 35px;
}

.TaaraMissionRight {
  flex: 1;
  font-size: 24px;
  color: #6a6a6a;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: -5rem;
}

.TaaraMissionRight .para {
  line-height: 40px;
  width: 78%;
}


.WhyTaaraCreatedArea {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 70px;
  align-items: center;
  justify-content: center;
}

.WhyTaaraCreatedTitle {
  font-size: 35px;
  color: #533884;
  text-align: center;
}

.WhyTaaraCreatedVideo {
  margin-top: 20px;
  height: 620px;
  width: 85%;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .fade-in,
  .fade-out {
    /* Disabled transitions for performance on smaller screens */
    opacity: 1;
    transition: none;
  }

  .TaaraIntroImage {
    width: 95%;
    height: 450px;
    background-image: url("../../images/taara/TaaraBackground.avif");
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -70px center;
    background-color: white;
    border-radius: 20px;
  }

  .TaaraIntroRightImage {
    display: flex;
    flex-direction: column;
    justify-content: end;
    font-family: "Audiowide";
    font-size: 14px;
    background-position: bottom;
    color: white;
    border-radius: 20px;
    padding-bottom: 4rem;
  }
  .TaaraIntroLeftImage {
    display: none;
  }

  .TaaraFirstIntro {
    gap: 20px;
    height: 280px;
    width: 110%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    font-size: 15px;
    font-family: "Nohemi";
    text-align: center;
    background-color: url("../../images/right-vector.svg"),
      url("../../images/left-yellow-vector.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: 0px 270px, 100% -80px;
    background-size: 24%;
    z-index: -1;
    margin-bottom: 1.5rem;
  }
  .TaaraFirstIntroTopText {
    color: black;
    line-height: 24px;
  }

  .TaaraFaceArea {
    background-image: url("https://taara-quest.s3.eu-central-1.amazonaws.com/images/taara/TaaraFace.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-size: 20px;
    justify-content: end;
    width: 90%;
    border-radius: 20px;
    position: relative;
    top: -30px;
    height: 379px;
    padding-top: -20px;
    padding-bottom: 3rem;
  }

  .TaaraFaceArea > span {
    font-size: 10px;
    margin-top: 10px;
  }

  .TaaraOneLiners {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 10px;
    margin-top: 10px;
    height: auto;
    margin-bottom: 30px;
  }

  .TaaraReason {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: auto;
    padding: 28px 48px;
    text-align: center;
  }

  .TaaraReason > span {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
  }

  .TaaraSocial {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
    height: auto;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 20px;
    margin-top: 24px;
  }

  .TaaraSocialHeading {
    font-family: "Audiowide";
    color: #eb4176;
    font-size: 14px;
    margin-top: 20px;
  }

  .TaaraSocialCards {
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: start;
    justify-content: start;
    padding-left: 2rem;
  }

  .TaaraThoughts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    font-size: 16px;
    height: auto;
    margin-top: 30px;
  }

  .TaaraThoughts > span {
    margin-top: 20px;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .TaaraDescription {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .TaaraLeftDescription {
    width: 90%;
    flex: none;
    position: relative;
  }

  .TaaraRightDescription {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    font-size: 14px;
  }

  .TaaraStoryShare {
    font-size: 20px;
    height: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .TaaraIntroductionVideoTitle {
    width: 80%;
    font-size: 13px;
    padding: 0px 10px;
    height: 50px;
  }

  .TaaraIntroVideoArea {
    width: 90%;
    height: auto;
    margin-bottom: 80px;
  }

  .TaaraMissionArea {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
    align-items: center;
    justify-content: center;
    background-image: none;
    margin-top: -60px;
  }

  .TaaraMissionLeft {
    flex: 1;
    width: 100%;
    position: relative;
    right: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #eb4176;
    font-size: 16px;
    font-family: 'Audiowide';
  }

  .TaaraMissionLeft > span {
    font-size: 16px;
    margin-bottom: 20px;
    font-family: "Nohemi";
  }

  .TaaraMissionRight {
    font-size: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center ;
    text-align: center;
    margin-top: 0;
  }

  .TaaraMissionRight .para {
    line-height: 24px;
  }
  .WhyTaaraCreatedTitle {
    font-size: 18px;
    color: #533884;
    text-align: center;
  }

  .WhyTaaraCreatedArea {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
  }

  .WhyTaaraCreatedVideo {
    width: 90%;
    height: auto;
  }
}
