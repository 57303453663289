.taaraWorldFeatures {
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}


.taaraWorldCard {
     display: flex;
     flex-direction: column;
     height: 320px;
     align-items: center;
     justify-content: space-evenly;
     padding: 48px 40px;
     border-radius: 20px;
     color: white;
     gap: 18px;
   }
   
   .taaraWorldCardIcon {
     font-size: 58px;
   }
   
   .taaraWorldCardTitle {
     font-size: 28px;
     font-family: "Audiowide";
   }
   
   .taaraWorldCardContent {
     font-size: 14px;
     text-align: center;
   }

@media (max-width: 768px) {
  .taaraWorldFeatures {
    max-width: 100%;
    overflow: scroll;
  }
  .taaraWorldCardContent {
     font-size: 12px;
   }
 
   .taaraWorldCardTitle {
     font-size: 24px;
   }
 
   .taaraWorldCard {
     display: flex;
     flex-direction: column;
     width: 264px;
     height: auto;
     align-items: center;
     justify-content: space-evenly;
     padding: 30px 20px;
     border-radius: 20px;
     color: white;
     gap: 14px;
   }
}
