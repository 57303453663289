.accordian-container {
  padding: 0rem 4rem;
  border-radius: 20px;
  height: 89px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #d9d9d9;
  color: gray;
}

.accordian-faq-answer {
  font-size: 16px;
  color: gray;
  padding-left: 100px;
  line-height: 28px;
  margin: 1rem 0rem;
  width: 500px;
}
.question {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.plus-icon {
  margin-right: 1.5rem;
  font-size: 1.5rem;
}

.opened {
  background-color: #eb4176;
  color: #fff;
}

.hidden {
  display: hidden;
}

@media (max-width: 768px) {
  .accordian-container {
    height: 66px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #d9d9d9;
    color: gray;
    padding: 0rem 2rem;
  }
  .question {
    font-size: 14px;
    line-height: 18px;
  }
  .plus-icon {
    margin-right: 1rem;
    font-size: 14px;
  }
  .accordian-faq-answer {
    font-size: 12px;
    padding-left: 58px;
    line-height: 18px;
    margin: 0.5rem 0rem;
    width: 70%;
  }
  .opened {
    background-color: #eb4176;
    color: #fff;
  }
}
