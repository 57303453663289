.OurAmbassadorsSection {
  display: flex;
  flex-direction: column;
  gap: 117px;
  margin-bottom: 2rem;
}

.OurAmbassadorsContainer {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0px 64px;
}

.AmbassadorText {
  font-size: 43px;
  color: #252153;
  font-family: "Nohemi";
}

.AmbassadorMainHeading {
  color: #eb4176;
  font-size: 43px;
  font-family: "Audiowide";
  margin-top: 0.5rem;
}

.OurAmbassadorsRightContent {
  display: flex;
  justify-content: end;
}

.OurAmbassadorsRightContent p {
  font-size: 20px;
  color: gray;
  text-align: justify;
  width: 60%;
  line-height: 32px;
}
.AllAmbassadors {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  padding: 0px 64px;
}

.AmbassadorCard {
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 20px;
  color: white;
  height: 400px;
  position: relative;
}
.AmbassadorCard > p {
  position: absolute;
  bottom: 2rem;
}
.AmbassadorCard img {
  border-radius: 20px;
}

@media (max-width: 768px) {
  .OurAmbassadorsSection {
    gap: 72px;
  }
  .OurAmbassadorsContainer {
    grid-template-columns: 1fr;
  }
  .OurAmbassadorsRightContent {
    display: flex;
    justify-content: center;
  }

  .OurAmbassadorsRightContent p {
    font-size: 12px;
    text-align: center;
    width: 90%;
    line-height: 20px;
    margin-top: 2rem;
  }
  .AmbassadorText {
    font-size: 14px;
    text-align: center;
  }

  .AmbassadorMainHeading {
    font-size: 14px;
    text-align: center;
  }

  .AllAmbassadors {
    /* grid-template-columns: 1fr; */
    max-width: 100%;
    overflow-x: scroll;
    padding: 0px 0px 0px 32px;
  }

  .AmbassadorCard {
    height: 300px;
    width: 300px;
    background-size: cover;
    background-position: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .AmbassadorCard > p {
    bottom: 1.5rem;
  }
}
