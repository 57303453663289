.profileCard {
	display: flex !important;
	background-color: #ffffff;
	border-radius: 20px;
	gap: 50px;
	/* align-items: center; */
	justify-content: center;
	padding: 20px;
	margin: 0px 10px;
	margin-top: 2rem;
	height: 450px;
	width: 600px;
	cursor: pointer;
	-webkit-box-shadow: 11px 11px 35px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 11px 11px 35px -8px rgba(0, 0, 0, 0.75);
	box-shadow: 11px 11px 35px -8px rgba(0, 0, 0, 0.75); 
	animation: scroll 10s linear infinite;
}

.leftProfileCard {
	flex: 1;
	display: flex;
	padding-top: 2rem;
}

.rightProfileCard {
	flex: 1;
	overflow-y: hidden;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	gap: 30px;
	justify-content: space-evenly;
}

.leftProfileCard  img {
	height: 200px;
	width: 200px;
	border-radius: 50%;
}

.profileCardTitle {
	font-size: 30px;
	color: #252153;
}

.profileCardDescription {
	font-size: 18px;
	color: #424242;
}

.profileCardDesignation {
	font-size: 14px;
	color: #252153;
}

@media (max-width: 768px) {
	.profileCard {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: auto;
		width: 100%;
		padding: 10px;
		gap: 20px;
		min-height: 500px; /* Ensure minimum height for consistency */
	}

	.leftProfileCard  img {
		height: 150px;
		width: 150px;
		
	}

	.profileCardTitle {
		font-size: 24px;
	}

	.profileCardDescription {
		font-size: 16px;
	}

	.profileCardDesignation {
		font-size: 12px;
	}
}
