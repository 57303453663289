.blog-container {
  position: relative;
  top: -128px; /* move the blog container up to overlap with navbar */
  width: 100%;
  background-image: url("../../images/blog/blogBackground.svg");
  background-repeat: no-repeat;
  background-position: 100% 0%;
  z-index: 1;
}

.related-content-container {
  position: relative;
  width: 100%;
}

.blog-content {
  padding-top: 180px;
  width: 100%;
  display: flex;
  gap: 20px;
  font-size: 20px;
  font-family: "Nohemi";
}

.blog-content-left {
  width: 50%;
  padding-left: 3rem;
}

.blog-content-left > .small-heading {
  font-size: 22px;
}
.blog-content-left > h3 {
  font-family: "Audiowide";
  color: #eb4176;
  font-size: 43px;
}
.blog-content-left > div > p {
  font-size: 15px;
  line-height: 22px;
  font-family: "Nohemi";
  width: 298px;
  text-align: justify;
}
.blog-content-right {
  width: 50%;
}

.post-image {
  flex: 1;
  height: 400px;
  width: 497px;
  border-radius: 20px;
  background-color: #eee;
}

.all-posts-header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 3rem;
  margin-top: 5rem;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading-container > .all-posts-heading {
  font-size: 43px;
  margin: 0;
  padding: 0;
  color: #533884;
}

.filter {
  display: none;
}

.all-posts-header > .all-posts-tags {
  display: flex;
  gap: 8px 4px;
  flex-wrap: wrap;
}

.blog-list-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
  margin-top: 2rem;
  padding: 0 2rem;
}

.show-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-more-button {
  display: none;
}

.small-heading-mobile{
  display: none;
}

@media (max-width: 768px) {
  .blog-container {
    background-image: none;
  }
  .blog-content {
    flex-direction: column-reverse;
    gap: 48px;
    padding-top: 0px;
    margin-top: 150px;
  }
  .blog-content-left {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  .blog-content-left > .small-heading {
    font-size: 15px;
    display: none;
  }
  .blog-content-left > h3 {
    font-size: 25px;
  }
  .blog-content-left > div > p {
    font-size: 12px;
    text-align: center;
    width: auto;
    padding: 0 3rem;
  }
  .blog-content-right {
    width: 100%;
  }
  .post-image {
    height: 366px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
  .blog-list-container {
    grid-template-columns: 1fr;
    padding: 0;
    gap: 25px;
  }
  .all-posts-header {
    gap: 2rem;
    padding-left: 0;
    margin-top: 4rem;
  }
  .filter {
    display: block;
  }
  .heading-container .all-posts-heading {
    font-size: 24px;
    margin: 0;
    padding: 0;
    color: #533884;
  }
  .all-posts-header > .all-posts-tags {
    display: none;
  }
  .show-more-button {
    display: block;
    margin-top: 2rem;
    background: white;
    border: none;
    padding: 1rem;
    font-size: 10px;
    box-shadow: 3px 3px 6px #ddd;
    border-radius: 10px;
    font-family: "Nohemi";
  }
  .small-heading-mobile{
    display: block;
    font-size: 15px;
    text-align: center;
    margin-bottom: 36px;
  }
}
