.faq-heading {
  font-family: "Audiowide";
  color: #eb4176;
  font-size: 45px;
  text-align: center;
  margin: 0;
  padding: 0;
}
.faq-sub-heading{
  font-size: 20px;
  text-align: center;
  line-height: 16px;
}
.faq-container {
  display: flex;
  gap: 50px;
  margin: 80px 0px;
}
.faq-container-left {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.faq-container-right {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.faq-container-left > .heading {
  color: #533884;
  font-size: 22px;
}
.faq-container-left > .faq-questions {
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.faq-questions > .question {
  font-size: 18px;
  line-height: 24px;
}

@media (max-width: 768px) {
  .faq-heading{
    font-size: 34px;
  }
  .faq-sub-heading{
    font-size: 14px;
  }
  .faq-container{
    flex-direction: column;
    margin: 36px 0px;
  }
  .faq-container-left{
    display: none;
  }
  .faq-container-right{
    width: 100%;
    gap: 8px;
  }
}