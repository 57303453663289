/* .EverntCardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.EventCard {
  width: 100%;
  background-color: #533884;
  height: 479px;
  border-radius: 20px;
  position: relative;
}

.CardContent {
  height: 100%;
  padding: 46px 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.EventstepCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* padding: 20px; */
}

.EventstepCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 479px;
  border-radius: 20px;
  background-color: #533884;
  padding: 46px 32px;
  box-sizing: border-box; /* This ensures padding is included in the width */
  width: 100%; /* Set width to 100% of its grid cell */
}

.EventstepCardContainerFlipped {
  /* position: absolute; */
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 479px;
  border-radius: 20px;
  background-color: #533884;
  padding: 46px 32px;
  box-sizing: border-box; /* This ensures padding is included in the width */
  width: 100%; /* Set width to 100% of its grid cell */
}

.EventfrontStepCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.EventtopStepCardText {
  font-size: 20px;
  color: #ffffff;
}

.EventbelowStepCard {
  display: flex;
  flex-direction: column;
}

.EventbottomStepCardCount {
  color: #e2b44b;
  font-size: 22px;
}

.EventbelowStepCardTitle, .EventbackCardbelowStepCardTitle {
  color: white;
  font-size: 34px;
  font-family: "Nohemi";
}

@media(max-width: 768px){
   .EventstepCards{
    grid-template-columns: 1fr;
   }
   .EventstepCardContainer, .EventstepCardContainerFlipped {
    height: 240px;
  }
  .EventfrontStepCard{
    align-items: center;
  }
  .EventstepCardContainerFlipped{
    justify-content: center;
    align-items: center;
  }
  .hide-on-mobile{
    display: none;
  }
  .EventstepCardContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .EventbelowStepCard{
    display: flex;
    align-items: center;
  }
  .EventbelowStepCardTitle {
    font-size: 26px;
    text-align: center;
    width: 80%;
    line-height: 28px;
  }

  .EventbackCardbelowStepCardTitle {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
  }

}