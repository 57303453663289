.empower-her-access-container {
  width: 100%;
  height: 800px;
  background-image: url("../../../images/pricing/pricing_vector.svg");
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .empower-her-access-container{
    background-image: none;
    height: auto;
  }
}