.launchCountdownArea {
	margin-top: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
	justify-content: center;
	width: 100%;
	background-color: #eb4176;
	color: white;
	border-radius: 20px;
	padding: 54px 0px;
}

.launchCountdownTitle {
	font-size: 25px;
}

.launchCountdownTimer {
	font-size: 54px;
	font-family: "Audiowide";
}

.launchCountdownButton {
	width: 200px;
	border-radius: 10px;
	padding: 15px 35px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #252153;
	cursor: pointer;
	transition: transform 0.5s ease;
}

.launchCountdownButton:hover {
	transform: scale(1.2);
}

@media (max-width: 768px) {
	.launchCountdownArea {
		/* height: 80px; */
		padding: 25px 0px;
		gap: 18px;
	}

	.launchCountdownTitle {
		font-size: 14px;
	}

	.launchCountdownTimer {
		font-size: 18px;
	}

	.launchCountdownButton {
		width: 134px;
		height: 34px;
		border-radius: 10px;
		padding: 3px 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #252153;
		font-size: 8px;
	}
}
