@font-face {
  font-family: "Nohemi";
  src: url("./fonts/Nohemi/Nohemi-Regular.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap");


html, body, * {
  font-family: 'Nohemi', sans-serif;
  padding: 0;
  margin: 0;
}