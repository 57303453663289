.navbarContainer {
  display: flex;
  max-width: 100%;
  height: 128px;
  padding-left: 62px;
  padding-right: 65px;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  position: relative;
  background-color: transparent;
}

.navbarLogo {
  cursor: pointer;
  background-image: url("../../images/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 135px;
  height: 70px;
  z-index: 1000;
}

.navbarLinks {
  display: flex;
  gap: 32px;
  font-size: 16px;
  font-weight: 100;
}

.navbarLink {
  cursor: pointer;
}

.accountButton {
  background-color: #533884;
  color: white;
  padding: 14px 32px;
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease, padding 0.3s ease,
    border 0.3s ease;
}

.accountButton:hover {
  color: #533884;
  background-color: white;
  border: 2px solid #533884;
  padding: 14px 30px;
  cursor: pointer;
}

.TaaraLinkPlaceholder {
  font-size: 26px;
  cursor: pointer;
}

.mobileNavbarContainer {
  display: none;
}

@media (max-width: 480px) {
  .navbarContainer {
    display: none;
  }

  .mobileNavbarContainer {
    display: flex;
    height: 60px;
    /* width: 100%; */
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #fff;
    z-index: 999;
  }

  .navbarLogo {
    width: 100px; /* Adjusted size for mobile */
    height: 40px; /* Adjusted size for mobile */
    background-size: contain;
  }

  .hamburgerMenu {
    cursor: pointer;
    font-size: 28px;
    padding: 10px;
    /* margin-right: 30px; */
  }

  .navbarMenuDropdown {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    width: 50%;
    box-shadow: 0px 100px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    opacity: 90%;
    border-radius: 25px;
  }

  .navbarMenuDropdownClosed {
    display: none;
  }

  .navbarLink,
  .signUpButton {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    font-size: 14px; /* Adjusted font size for mobile */
  }

  .navbarLink:last-child,
  .signUpButton {
    margin-right: 20px;
    background-color: #533884;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 14px; /* Slightly larger for better touch targets */
    transition: background-color 0.3s ease, color 0.3s ease, padding 0.3s ease,
      border 0.3s ease;
    border-bottom: none;
  }

  .signUpButton:hover {
    color: #533884;
    background-color: white;
    border: 2px solid #533884;
    padding: 10px 22px;
  }
}
