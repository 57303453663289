.TaaraAmbassadorContainer {
  display: flex;
  flex-direction: column;
  gap: 98px;
}

.TaaraAmbassadorHeading {
  color: #eb4176;
  text-align: center;
  font-size: 43px;
  font-family: "Audiowide";
}

.TaaraAmbassadorGrids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.TaaraAmbassadorGrid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eb4176;
  border-radius: 20px;
  padding: 36px 34px;
  height: 280px;
}

.TaaraAmbassadorText {
  color: white;
  font-size: 24px;
  font-family: "Nohemi";
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  .TaaraAmbassadorContainer {
    gap: 50px;
  }
  .TaaraAmbassadorGrids {
    grid-template-columns: 1fr;
  }
  .TaaraAmbassadorHeading {
    font-size: 14px;
  }
  .TaaraAmbassadorGrid {
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 65px 70px;
    gap: 44px;
  }
  .TaaraAmbassadorText {
    font-size: 16px;
    text-align: center;
    line-height: 20px;
  }
}
