.TestimonialCarouselCardContainer {
	display: flex;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
}
