.tag-container{
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 0.8rem 1.5rem;
     font-size: 16px;
     color: gray;
     border: 1px solid gray;
     border-radius: 50px;
     width: fit-content;
     white-space: nowrap;
     cursor: pointer;
     align-self: flex-start
}

.selected{
     background-color: gray !important;
     color: white !important;
}