.footerComponent {
	margin-top: 44px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	font-family: "Montserrat";
}

.topFooterArea {
	display: flex;
	width: 90%;
	gap: 20px;
	/* align-items: center; */
	justify-content: center;
}

.leftTopFooter {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.footerLogoText {
	text-align: justify;
	color: #9a9a9a;
	font-size: 15px;
}

.rightTopFooter {
	flex: 2;
	display: flex;
	justify-content: space-evenly;
}

.bottomFooterArea {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 100px;
	align-items: flex-start;
}

.footerLogo {
	background-image: url("../../images/logo.svg");
	width: 120px;
	height: 59px;
	cursor: pointer;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.rightTopFooterList {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.rightTopFooterListHeading {
	font-size: 19px;
	color: #484848;
	margin-bottom: 30px;
	font-weight: 600;
}

.rightTopFooterListElement {
	font-size: 15px;
	font-weight: 300;
	cursor: pointer;
}

.socialFooterIcons {
	margin-top: 10px;
	display: flex;
	font-size: 25px;
	gap: 20px;
}

.socialFooterIcons > a {
	text-decoration: none;
	color: inherit;
}

.socialFooterIcons > a:visited {
	text-decoration: none;
	color: inherit;
}

.socialFooterIcons > i {
	cursor: pointer;
	color: #484848;
}

.footerDivider {
	width: 100%;
	margin: 10px 0px;
	color: #e0e2e6;
}

.mobileFooterComponent {
	display: none;
}

@media (max-width: 768px) {
	.rightTopFooterListHeading{
		font-size: 1rem;
	}
	.rightTopFooterListElement{
		font-size: 14px;
	}
	.mobileFooterComponent {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 4rem;
		padding-top: 2rem;
		border-top: 1px solid #ddd;
	}

	.mobileFooterComponent > hr {
		width: 100%;
	}

	.footerComponent {
		display: none;
	}

	.mobileFooterIcons {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		width: 80%;
		gap: 40px;
		font-size: 25px;
	}
}
