.PromiseYouEverythingContainer{
     margin-top: 2rem;
}
.PromiseYouEverythingContent {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  gap: 39px;
}
.PromiseYouEverythingHeading {
  font-family: "Nohemi";
  font-size: 43px;
  color: #533884;
  text-align: center;
}

.PromiseYouEverythingDescription {
  font-size: 20px;
  width: 70%;
  text-align: center;
  line-height: 28px;
}

@media (max-width: 768px) {
  .PromiseYouEverythingContent {
    width: 100%;
    gap: 18px;
  }
  .PromiseYouEverythingHeading {
    font-size: 14px;
  }

  .PromiseYouEverythingDescription {
    font-size: 12px;
    line-height: 24px;
    width: 80%;
  }
}
