@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@font-face {
  font-family: "Audiowide";
  src: url("../../fonts/Audiowide/Audiowide-Regular.ttf") format("truetype");
}

.landingPageContainer {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
}
.rightGuideAreaForMobile {
  display: none;
}

.landingPageComponents {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
}

.TestimonialCarouselContainer {
  width: 100%;
  height: 600px;
  margin-top: 3rem;
}

.waitlistAreaTitle {
  font-size: 32px;
  font-weight: bold;
  color: white;
  z-index: 1;
  font-family: "Audiowide";
}

.waitlistAreaButton {
  z-index: 1;
  color: white;
  width: 200px;
  height: 47px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  font-size: 17px;
  cursor: pointer;
  transition: background-color 0.5s ease, color 0.5s ease, padding 0.5s ease,
    border 0.5s ease;
}

.waitlistAreaButton:hover {
  color: #07262a;
  background-color: white;
  border: none;
}

.brandBannerList {
  margin-top: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}

/* .brandBannerList .brandBanner:nth-child(3) > img,
.brandBannerList .brandBanner:nth-child(4) > img {
	width: 150px;
} */

.brandBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aeaeae;
  gap: 5px;
  font-size: 30px;
  font-weight: bold;
}

.brandBanner img {
  height: 170px;
  width: 200px;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.secondWaitlistArea {
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #252153;
  border-radius: 20px;
  height: 435px;
}

.secondWaitlistArea {
  color: #959595;
  transition: color 0.2s ease-in-out;
}

.secondWaitlistArea:hover {
  color: #fff;
}

.secondWaitlistAreaContent {
  width: 530px;
  font-size: 32px;
  text-align: center;
}

.secondWaitlistAreaContent > span {
  color: #959595;
}

.secondWaitlistAreaButton {
  background-color: #eb4176;
  color: white;
  width: 200px;
  height: 46px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 17px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.secondWaitlistAreaButton:hover {
  transform: scale(1.2);
}
.backCardQuest1 {
  background-image: url("https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/quest1bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  opacity: 0.8;
}

.backCardQuest2 {
  background-image: url("https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/quest2bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  opacity: 0.8;
}

.backCardQuest3 {
  background-image: url("https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/quest3bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  opacity: 0.8;
}

.graphicalBackgroundArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url("../../images/left-vector.svg"),
    url("../../images/right-vector.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top left, top right;
  z-index: 0;
}

.topGraphicalArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 175px;
  gap: 30px;
}

.topGraphicalAreaText {
  color: #eb4176;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Audiowide";
}

.topGraphicalAreaText > span {
  color: #252153;
  font-size: 30px;
  font-family: "Nohemi";
}

.topGraphicalAreaParagraph {
  font-size: 25px;
  color: #6a6a6a;
  width: 475px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.topGraphicalAreaParagraph > span {
  font-weight: bold;
}

.bottomGraphicalArea {
  margin-top: 150px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.leftGraphicsArea {
  width: 500px;
  height: 405px;
  border-radius: 20px;
  background-color: #533884;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  gap: 30px;
}

.leftGraphicsAreaHeader {
  font-size: 26px;
  text-align: left;
  color: white;
}

.leftGraphicsAreaContent {
  display: flex;
  flex-direction: column;
  color: #e2b44b;
  font-size: 24px;
  font-weight: bold;
}

.leftGraphicsAreaButton {
  width: 200px;
  padding: 15px 25px;
  background-color: #eb4176;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 46px;
  color: white;
  font-size: 17px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.leftGraphicsAreaButton:hover {
  transform: scale(1.2);
}

.rightGraphicsArea {
  flex: 2;
  height: 405px;
  width: 850px;
}
.rightGraphicsArea img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.unvelingArea {
  width: 100%;
  display: flex;
  height: 380px;
  gap: 20px;
}

.leftUnvelingArea,
.rightUnveilingArea {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftUnvelingArea {
  color: #eb4176;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: "Audiowide";
  margin-left: 150px;
}

.leftUnvelingArea > span {
  color: #252153;
  font-size: 30px;
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Nohemi";
}

.rightUnveilingAreaText {
  width: 50%;
  text-align: justify;
  font-size: 20px;
  color: #6a6a6a;
}

.stepCards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.stepCardContainer {
  display: flex;
  /* width: 350px; */
  flex-direction: column;
  justify-content: space-evenly;
  height: 347px;
  border-radius: 20px;
  background-color: #533884;
  padding: 50px 43px;
}

.stepCardContainerFlipped {
  /* position: absolute; */
  display: flex;
  width: 350px;
  flex-direction: column;
  justify-content: space-evenly;
  height: 347px;
  border-radius: 20px;
  padding: 100px 42px;
}

.topStepCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 60px;
}

.topStepCardText {
  font-size: 20px;
  color: #ffffff;
}

.topStepCardIcon > i {
  font-size: 57px;
  color: white;
}

.bottomStepCard {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.bottomStepCardCount {
  color: #e2b44b;
  font-size: 22px;
}

.bottomStepCardTitle {
  color: white;
  font-size: 34px;
  font-family: "Audiowide";
}

.moreQuests {
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreQuestsDiv {
  margin-top: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  border-radius: 10px;
  padding: 15px 35px;
  -webkit-box-shadow: 0px 20px 38px -8px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 20px 38px -8px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 20px 38px -8px rgba(0, 0, 0, 0.42);
}

.guideArea {
  margin-top: 80px;
  width: 100%;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/top-vector.svg"),
    url("../../images/bottom-vector.svg");
  background-position: top right, bottom right;
  background-repeat: no-repeat, no-repeat;
}

.guideAreaWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  padding-right: 220px;
  padding-bottom: 150px;
  gap: 50px;
}

.leftGuideArea {
  display: flex;
  max-width: 300px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 42px;
}

.rightGuideArea {
  /* background-image: url("../../images/landing/TaaraFront.webp");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center; */
  border-radius: 10px;
  max-width: 500px;
  width: 500px;
  height: 500px;
  /* margin-left: -340px; */
}
.rightGuideArea img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.leftGuideAreaTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.leftGuideAreaTitleTop {
  color: #eb4176;
  font-size: 35px;
  font-family: "Audiowide";
}

.leftGuideAreaTitleBottom {
  color: #252153;
  font-size: 35px;
  font-family: "Nohemi";
}

.leftGuideAreaContent {
  font-size: 20px;
  text-align: justify;
  color: #252153;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.leftGuideAreaButton {
  background-color: #252153;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 35px;
  border-radius: 10px;
  transition: transform 0.5s ease;
  cursor: pointer;
}

.leftGuideAreaButton:hover {
  transform: scale(1.2);
}

.taaraWorldArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 85px;
}

.taaraWorldHeading {
  width: 56%;
  color: #533884;
  font-size: 50px;
  text-align: center;
}

.taaraWorldHeading > span {
  color: #252153;
}

.taaraWorldFeatures {
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.courageCard {
  background-color: #e2b44b;
}

.empathyCard {
  background-color: #eb4176;
}

.learningCard {
  background-color: #252153;
}

.communityCard {
  background-color: #533884;
}

.videoContainer {
  background-color: white;
  height: 800px;
  margin-top: 86px;
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
}

.videoContainer > iframe {
  width: 100%;
  height: auto;
  display: block;
}

.testimonialSignup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.testimonialContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  background: url("../../images/left-yellow-vector.svg") no-repeat left 0 bottom -50px,
    /* Slide left curve down */ url("../../images/right-purple-vector.svg")
      no-repeat right 0 top -50px; /* Slide right curve up */
  background-size: 30% auto, 36% auto;
}

.testimonialHeading {
  margin-top: 130px;
  margin-left: 40px;
  font-size: 45px;
  color: #eb4176;
  font-family: "Audiowide";
}

.testimonialHeading > span {
  color: #252153;
  font-family: "Nohemi";
}

/* .testimonialCarousel {
	display: flex;
	overflow: hidden;
	margin-bottom: 130px;
} */

/* @keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-100%);
	}
} */

.signupContainer {
  background-color: #4e327a;
  padding: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  color: white;
  width: 900px;
  margin: 0;
  position: relative;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -350px;
}

.taaraRoundLogo {
  position: absolute;
  top: -40px; /* Adjust the position as needed */
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 50%;
}
.taaraRoundLogo img {
  width: 150px;
  height: auto;
}

.signupContainer > h2 {
  margin-top: 72px;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  font-family: "Audiowide";
}

.formComponents {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 28px;
}

.formComponents > input {
  background-color: #252153;
  color: white;
  border-radius: 20px;
  border: 1px solid white;
  padding: 10px;
  text-align: center;
  width: 340px;
  font-size: 16px;
}

.formComponents > button {
  cursor: pointer;
  background-color: #eb4176;
  color: #ffffff;
  border-radius: 10px;
  font-size: 17px;
  padding: 15px 35px;
  border: none;
}

.rightGuideAreaForMobile {
  display: none;
  height: 100px;
}

@media (max-width: 768px) {
  .TestimonialCarouselContainer {
    height: 580px;
  }

  .leftGuideAreaButton {
    font-size: 10px;
  }
  .landingPageContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }

  .landingPageComponents {
    padding: 0px 16px;
  }

  .brandBannerList {
    margin-top: 26px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
  }

  .brandBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aeaeae;
    gap: 5px;
  }

  .brandBanner img {
    height: 45px;
    width: 50px;
  }

  .secondWaitlistArea {
    height: 250px;
    margin-top: 26px;
    display: flex;
    padding: 0px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #252153;
    border-radius: 20px;
  }

  .secondWaitlistAreaContent {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  .secondWaitlistAreaButton {
    background-color: #eb4176;
    color: white;
    width: 110px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    font-size: 10px;
    cursor: pointer;
    transition: transform 0.5s ease;
  }

  .graphicalBackgroundArea {
    width: 100%;
    background-size: 20% auto, 20% auto; /* Scale images for mobile */
    background-position: top left, topright;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .topGraphicalArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 95px;
    gap: 30px;
  }

  .topGraphicalAreaText {
    color: #eb4176;
    font-size: 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Audiowide";
  }

  .topGraphicalAreaText > span {
    color: #252153;
    font-size: 16px;
    font-family: "Nohemi";
  }

  .topGraphicalAreaParagraph {
    font-size: 14px;
    width: 60%;
  }

  .bottomGraphicalArea {
    margin-top: 75px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .leftGraphicsArea {
    width: 100%;
    height: 266px;
    background-image: url("https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/TaaraBack.webp");
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: 20px;
    gap: 18px;
  }

  .leftGraphicsAreaHeader {
    font-size: 12px;
  }

  .leftGraphicsAreaContent {
    font-size: 12px;
  }

  .leftGraphicsAreaButton {
    background-color: #eb4176;
    color: white;
    border-radius: 10px;
    display: flex;
    width: 110px;
    height: 30px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-size: 10px;
    cursor: pointer;
    transition: transform 0.5s ease;
  }

  .rightGraphicsArea {
    display: none;
  }

  .unvelingArea {
    flex-direction: column;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 280px;
  }

  .leftUnvelingArea,
  .rightUnveilingArea {
    width: 100%;
    flex: 0;
    align-items: center;
    justify-content: center;
    text-align: center; /* Center text for better appearance on mobile */
  }

  .leftUnvelingArea > span {
    font-size: 14px;
    align-items: center;
    justify-content: center;
  }

  .leftUnvelingArea {
    font-size: 18px;
    align-items: center;
    /* display: flex;
		justify-content: center; */
    text-align: center;
    margin-left: 0px;
  }

  .rightUnveilingAreaText {
    font-size: 14px;
    text-align: center;
    width: 80%;
    line-height: 20px;
  }

  .stepCards {
    grid-template-columns: 1fr;
  }

  .stepCardContainer {
    /* width: 80%; */
    padding: 30px;
    border-radius: 20px;
    display: flex;
    /* align-items: center; */
    justify-content: space-evenly;
    height: 228px;
  }

  .stepCardContainerFlipped {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 20px;
    padding: 20px;
    height: 228px;
  }
  .topStepCard {
    margin-bottom: 30px;
  }

  .topStepCardIcon > i {
    font-size: 40px;
    color: white;
  }

  .bottomStepCardCount {
    font-size: 18px;
  }

  .bottomStepCardTitle {
    font-size: 25px;
  }

  .guideArea {
    margin-top: 20px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../images/top-vector.svg"),
      url("../../images/bottom-vector.svg");
    background-size: 50%, 50%;
    background-position: top left, right;
    background-repeat: no-repeat, no-repeat;
  }

  .leftGuideArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .guideAreaWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 60px 80px;
    gap: 150px;
  }

  .rightGuideAreaForMobile {
    display: flex;
    height: 300px;
    width: 250px;
    background-image: url("https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/TaaraFront.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    margin-right: -10px;
  }

  .rightGuideArea {
    display: none;
  }

  .leftGuideAreaContent {
    font-size: 12px;
    text-align: center;
    color: #252153;
  }

  .taaraWorldArea {
    width: 100%;
    margin-top: 10px;
  }

  .taaraWorldHeading {
    width: 95%;
    font-size: 20px;
    display: flex;
    flex-direction: column;
  }

  .taaraWorldFeatures {
    max-width: 100%;
    overflow: scroll;
  }

  .videoContainer {
    display: flex;
    background-color: white;
    height: auto;
    margin-top: 30px;
    border-radius: 20px;
    width: 100%;
    cursor: pointer;
  }

  .testimonialSignup {
    display: flex;
    width: 100%;
  }

  .testimonialContainer {
    display: flex;
    background: none;
    /* background: 
    url("../../images/left-yellow-vector.svg") no-repeat left 70 bottom 50px, 
    url("../../images/right-purple-vector.svg") no-repeat right -80px top 30px;   */
  }

  .testimonialHeading {
    margin-top: 30px;
    font-size: 25px;
    color: #eb4176;
    font-family: "Audiowide";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .testimonialHeading > span {
    color: #252153;
    font-family: "Nohemi";
    font-size: 15px;
  }

  .signupContainer {
    width: 100%;
    padding: 10px;
    bottom: -340px;
  }

  .signupContainer > h2 {
    position: relative;
    margin-top: 100px;
    margin-bottom: 60px;
  }

  .formComponents {
    position: relative;
    margin-top: -45px;
    padding-bottom: 20px;
  }

  .formComponents > input {
    width: 120%;
  }
}
