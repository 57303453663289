.AmbassadorsContainer {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.SomethingBigger {
  /* height: 405px; */
  background-color: #252153;
  padding: 104px 0px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.SomethingBigger > .heading {
  font-size: 32px;
  color: #fff;
  font-family: "Nohemi";
  margin: 0;
  padding: 0;
}

.SomethingBigger > .description {
  font-size: 24px;
  color: #959595;
  text-align: center;
  width: 480px;
  line-height: 35px;
  margin: 0;
  padding: 0;
}

.WaitListButton {
  display: none;
}

@media (max-width: 768px) {
  .AmbassadorsContainer {
    padding: 0 16px;
  }
  .SomethingBigger {
    padding: 50px 0px;
    gap: 30px;
  }
  .SomethingBigger > .heading {
    font-size: 20px;
  }

  .SomethingBigger > .description {
    font-size: 12px;
    width: 280px;
    line-height: 20px;
  }
  .WaitListButton {
    background-color: #eb4176;
    color: white;
    width: 110px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    cursor: pointer;
    transition: transform 0.5s ease;
  }
}
