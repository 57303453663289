.youAreNotAloneBackgroundArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -54px;
  height: 640px;
  background-image: url("../../../images/custom-quest/left-how-it-works.svg"),
    url("../../../images/custom-quest/right-how-it-works.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top left, top right;
  z-index: -1;
}

.youAreNotAloneBackgroundArea {
  width: 100%;
  background-size: 20% auto, 20% auto; /* Scale images for mobile */
  background-position: top left, topright;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.youAreNotAloneArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 175px;
  margin-bottom: 175px;
  gap: 30px;
}
