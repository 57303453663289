.AboutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url("../../images/about/BlueBackGroundHalfCircleRight.png"),
    url("../../images/about/redBackGroundHalfCirclELeft.png");
  background-repeat: no-repeat, no-repeat;
  background-position-x: right, left;
  background-position-y: 1%, 1%;
}

.ColoredBackgroundsContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around; /* Adjust as needed for spacing */
}
.coloredBackground {
  flex: 1; /* Gives equal width to both images */
  object-fit: cover;
  height: 250px; /* Set a fixed height or adjust as needed */
}
.redBackgroundImage,
.blueBackgroundImage {
  width: 100%;
  height: auto;
}
.FirstSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 600px;
  width: 90%;
  background-image: url("https://taara-quest.s3.eu-central-1.amazonaws.com/images/about/firstImage.webp");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
}

.FirstSection img {
  border-radius: 20px;
}

.FirstSectionText {
  color: white;
  font-size: 32px;
}

.FirstSectionText {
  position: absolute;
}

.FirstSectionText > span {
  font-weight: bold;
}

.SecondSection {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 250px;
  flex: 2;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  z-index: -1;
}
.SecondSectionText {
  font-size: 32px;
  color: #533884;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
}

.ThirdSection {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px;
  /* height: 530px; */
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.FourthSectionRightElement,
.ThirdSectionLeftElement {
  padding-left: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 43px;
  font-family: "Audiowide";
  color: #eb4176;
  justify-content: center;
}

.FourthSectionRightElement {
  padding-left: none;
  text-align: left;
  display: flex;
  justify-content: center;
  transform: none;
  background-color: #fff;
}

.ThirdSectionLeftElement > span {
  display: block;
  opacity: 1;
  transition: opacity 2s ease-in-out;
}

.FourthSection:hover .FourthSectionRightElement span {
  visibility: visible;
  opacity: 1;
}
.FourthSectionRightElement > span,
.ThirdSectionLeftElement > span {
  font-family: "Nohemi";
  font-size: 23px;
  margin-top: 60px;
  color: gray;
  width: 80%;
  display: none;
}

.ThirdSectionRightElement {
  flex: 1;
  height: 100%;
  border-radius: 20px;
  /* background-image: url("../../images/about/secondImage.webp");
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: #fff;
}

.ThirdSectionRightElement img {
  border-radius: 20px;
}

.FourthSection {
  display: flex;
  background-color: white;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px;
  /* height: 530px; */
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.FourthSectionLeftElement {
  flex: 1;
  height: 100%;
  border-radius: 20px;
  /* background-image: url("../../images/about/thirdImage.webp");
  background-size: cover;
  background-repeat: no-repeat; */
}

.FourthSectionLeftElement img {
  border-radius: 20px;
}

.FifthSection {
  width: 90%;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-top: 20px;
  border-radius: 20px;
  background-color: #533884;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 70px;
}

.FifthSectionTopText {
  font-size: 35px;
  font-family: "Nohemi";
  opacity: 50%;
  color: white;
}

.FifthSectionBottomText {
  font-size: 45px;
  font-family: "Nohemi";
  color: white;
}

.SixthSection {
  font-size: 45px;
  font-family: "Audiowide";
  color: #eb4176;
  margin-top: 60px;
  margin-bottom: 60px;
}

.SixthSection > span {
  color: #533884;
  font-size: 51px;
  font-family: "Nohemi";
}

.SeventhSection {
  display: flex;
  width: 70%;
  height: 400px;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.SeventhSectionImageElement {
  width: 400px !important;
  height: 400px !important;
}

.SeventhSectionImageElement img {
  border-radius: 20px;
}

.SeventhSectionTextElement {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.SeventhSectionTextElementHeader {
  font-size: 43px;
  font-family: "Nohemi";
  color: #533884;
}

.SeventhSectionTextElementContent {
  font-family: "Nohemi";
  font-size: 20px;
  color: gray;
  text-align: justify;
}

.EightSection {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.profile-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  padding: 20px;
}

.profile-card {
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  overflow: hidden;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 20px;
  color: white;
  height: 400px;
  position: relative;
  height: auto;
}
.profile-card > p {
  position: absolute;
  margin-bottom: 1rem;
}
.profile-card img {
  border-radius: 20px;
}

/* .profile-grid > span {
	font-size: 20px !important;
} */

.profile-grid > p {
  font-family: "Nohemi";
  font-size: 32px;
  color: white;
}

.NinthSection {
  font-size: 45px;
  font-family: "Audiowide";
  color: #eb4176;
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
}

.NinthSection > span {
  color: #533884;
  font-family: "Nohemi";
}

.TenthSection {
  display: flex;
  margin-top: 20px;
  width: 90%;
  height: 300px;
  gap: 20px;
}

.LeftTenthSection {
  flex: 8;
  /* background-image: url("../../images/about/fourthImage.webp");
  background-repeat: no-repeat;
  background-size: cover; */
  height: 300px;
  border-radius: 20px;
}
.LeftTenthSection img {
  border-radius: 20px;
  height: 100% !important;
}

.rightTenthSection {
  flex: 6;
  display: flex;
  flex-direction: column;
  background-color: #eb4176;
  color: white;
  font-family: "Nohemi";
  font-size: 23px;
  padding: 0px 40px;
  border-radius: 20px;
  text-align: left;
  justify-content: center;
}

.rightTenthSection > p {
  font-weight: bold;
}

/* Mobile devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  .AboutContainer {
    background-image: none;
    text-align: center;
  }

  .FirstSection {
    height: 400px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .FirstSectionText {
    font-size: 20px;
  }

  .SecondSection {
    height: 200px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px;
  }

  .SecondSectionText {
    font-size: 15px;
    background-color: transparent;
    color: white;
    background-color: #e2b44b;
    width: 100%;
    padding: 28px 50px;
    border-radius: 20px;
  }

  .ThirdSection,
  .FourthSection {
    flex-direction: column-reverse;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .FourthSection {
    flex-direction: column;
  }

  .ThirdSectionLeftElement,
  .ThirdSectionRightElement,
  .FourthSectionLeftElement,
  .FourthSectionRightElement {
    font-size: 15px;
    text-align: center;
    padding: 0;
  }
  .ThirdSectionLeftElement,
  .FourthSectionRightElement {
    padding: 36px 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 20px;
  }
  .FourthSectionRightElement > span,
  .ThirdSectionLeftElement > span {
    font-size: 12px;
    margin-top: 0px;
    display: block;
  }
  .ThirdSectionRightElement {
    margin-top: 5px; /* Adjust this value as needed */
  }

  .FifthSection {
    padding: 30px;
    text-align: center;
    gap: 18px;
  }
  .FifthSectionTopText {
    font-size: 12px;
  }

  .FifthSectionBottomText {
    font-size: 14px;
  }

  .SixthSection,
  .NinthSection {
    font-size: 14px;
    text-align: center;
  }
  .SixthSection{
    margin: 2rem 0px 0px 0px;
  }
  .SixthSection > span {
    font-size: 14px;
  }
  .NinthSection{
    margin: 24px 0px;
  }

  .SeventhSection {
    flex-direction: column;
    /* align-items: center; */
    gap: 20px;
    display: flex;
    /* justify-content: center; */
    text-align: center;
    margin-top: 4rem;
  }

  .SeventhSectionImageElement {
    width: 300px !important;
    height: 300px !important;
  }
  .SeventhSectionTextElement {
    gap: 36px;
  }
  .SeventhSectionTextElementHeader{
    font-size: 15px;
  }
  .SeventhSectionTextElementContent {
    font-size: 12px;
  }

  .profile-grid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    place-items: center;
    background-size: cover;
    background-position: center;
    margin-top: 2rem;
  }

  .profile-card {
    height: 300px;
    width: 300px;
    background-size: cover;
    background-position: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .profile-card > p {
    bottom: 1rem;
  }
  .TenthSection {
    flex-direction: column;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .LeftTenthSection {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .rightTenthSection {
    font-size: 18px;
    padding: 20px;
    text-align: center;
    display: none;
  }
}
