.SupportContainer {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  position: relative;
  background-image: url("../../../images/ambassadors/background.svg");
  background-position: 110% -20%;
  background-repeat: no-repeat;
}

.SupportDescription {
  color: #533884;
  font-family: "Nohemi";
  font-size: 43px;
  width: 55%;
  line-height: 48px;
  margin-left: 36px;
}

@media (max-width: 768px) {
  .SupportContainer {
    background-image: none;
    height: 80px;
    justify-content: center;
  }
  .SupportDescription {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-left: 0px;
    width: 80%;
  }
}
