.BarriersCardContainer {
  padding: 106px 0px;
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 0px 20px 38px -8px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 20px 38px -8px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 20px 38px -8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}
.BarriersCardContainerContent {
  width: 516px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.BarriersHeading {
  color: #533884;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  width: 400px;
}

.BarriesDescription {
  font-size: 20px;
  color: gray;
  line-height: 28px;
  width: 516px;
  text-align: center;
}

@media (max-width: 768px) {
  .BarriersCardContainer {
    padding: 68px 0px;
  }
  .BarriersHeading {
     color: #533884;
     font-size: 20px;
     line-height: 36px;
     text-align: center;
     width: 300px;
     line-height: 24px;
   }

   .BarriesDescription {
     font-size: 12px;
     width: 250px;
     text-align: center;
     line-height: 18px;
   }
}
