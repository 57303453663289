.blog-card-container {
  width: 100%;
  box-sizing: border-box;
  /* border: 1px solid #eee; */
  border-radius: 1rem;
  box-shadow: none;

}

.blog-card-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.blog-card-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.blog-card-content > .tags {
  display: flex;
  gap: 0.5rem;
}
.tags > .each-tag {
  padding: 4px 8px;
  border: 1px solid gray;
  font-size: 10px;
  color: #9d9d9d;
  border-radius: 8px;
  white-space: nowrap;
}

.blog-card-heading {
  font-size: 20px;
  font-family: "Audiowide";
  color: #eb4176;
  width: 70%;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-by {
  font-size: 12px;
}

@media (max-width: 768px) {
  .blog-card-container {
    padding: 0px;
    margin: 0;
    box-shadow: 3px 3px 6px #eee;
  }
  .blog-card-image {
    width: 100%;
    max-width: none;
  }
  .blog-card-heading {
    font-size: 16px;
  }
}
