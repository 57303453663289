.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 41px 28px;
  border-radius: 8px;
  width: 80%;
  max-height: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.modal-header > h2{
     padding: 0;
     margin: 0;
     font-size: 1.5rem;
     color: #533884;
}
.modal-content > .modal-header{
     display: flex;
     align-items: center;
     justify-content: space-between;
}
.modal-content > .filter-tags{
     display: flex;
     flex-direction: column;
     gap: 0.5rem;
}
.modal-content > button{
     border: none;
     background:  #eb4176;
     color: #fff;
     font-size: 10px;
     padding: 0.75rem 1rem ;
     border-radius: 4px;
     width: 60px;
}
.cancal-icon{
     cursor: pointer;
}

.modal-content > .filter-tags{
     display: flex;
     gap: 8px;
     width: auto;
}

@media (min-width: 768px){
     .modal-overlay{
          display: none;
     }
}