.hero-container {
  height: 720px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.hero-container img{
  border-radius: 20px;
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
}

.overlayImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.overlayImageText {
  font-size: 32px;
  font-family: "Audiowide";
  margin-bottom: 10px;
}

.overlayImageButton {
  height: 46px;
  padding: 10px 30px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.overlayImageButton:hover {
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .hero-container {
    height: 360px;
  }
  .overlayImageText {
    font-size: 10px;
  }

  .overlayImageButton {
    height: 23px;
    width: 80px;
    padding: 2px 10px;
    font-size: 8px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
  }
  .hero-container  img {
    height: 360px !important;
    width: 100% !important;
    object-fit: cover !important;
  }
}
