.AskTaaraArea {
  background-image: url("../../images/top-right-red-vector.svg"),
    url("../../images/bottom-right-yellow-vector.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top left, bottom right;
  width: 100%;
  height: 820px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TaaraFormContainer {
  width: 60%;
  height: 500px;
  border-radius: 20px;
  background-color: #4e327a;
  color: #ffffff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.LeftAskTaaraArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  justify-content: space-between;
}

.taaraRoundLogo {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 50%;
}
.taaraRoundLogo img {
  width: 150px;
  height: auto;
}

.RightAskTaaraArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LeftAskTaaraRoundLogo {
  position: relative;
  background-image: url("../../images/round-logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 200px;
  width: 200px;
  top: -90px;
  right: calc(-50% - 80px);
}

.LeftAskTaaraTextArea {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: -70px;
  margin-left: 30px;
}

.LeftAskTaaraTitle {
  font-family: "Audiowide";
  font-size: 32px;
  color: white;
}

.LeftAskTaaraText {
  font-size: 25px;
  text-align: left;
}

.LeftAskTaaraButton {
  background-color: #eb4176;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  cursor: pointer;
}

.LeftAskTaaraButton:hover {
  background-color: white;
  color: #eb4176;
  border: 1px solid #eb4176;
  height: 48px;
  width: 148px;
}

.taaraFormComponents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* z-index: 1000; */
}

.taaraFormComponents > textarea,
.taaraFormComponents > input {
  background-color: #252153;
  color: white;
  border-radius: 20px;
  border: 1px solid white;
  padding: 10px;
  text-align: center;
  width: 340px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.taaraFormComponents > textarea {
  resize: none;
  height: 200px;
}

.RightAskTaaraButtonMobile {
  display: none;
}

@media (max-width: 768px) {
  .AskTaaraArea {
    background-image: none;
    background-repeat: no-repeat, no-repeat;
    background-position: top left, bottom right;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }

  .LeftAskTaaraRoundLogo {
    position: relative;
    background-image: url("../../images/round-logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 200px;
    width: 200px;
    top: -70px;
    right: -10px;
  }

  .TaaraFormContainer {
    width: 90%;
    height: auto;
    border-radius: 20px;
    background-color: #4e327a;
    color: #ffffff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .LeftAskTaaraTextArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-left: 0px;
    display: none;
  }

  .LeftAskTaaraTitle {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .LeftAskTaaraText {
    font-size: 20px;
  }

  .LeftAskTaaraButton {
    display: none;
  }

  .RightAskTaaraButtonMobile {
    background-color: #eb4176;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .RightAskTaaraArea {
    width: 90%;
    margin-top: 60px;
  }

  .taaraFormComponents > textarea,
  .taaraFormComponents > input {
    display: flex;
    flex-direction: column;
    width: 120%;
    font-size: 15px;
  }
}
